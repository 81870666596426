import * as Yup from 'yup';
import i18n from 'i18next';

export type InitialValues = {
  memberEmail?: string;
  memberRole: string;
};

export const initialValues: InitialValues = {
  memberEmail: '',
  memberRole: '',
};

type ValidationFn = (addedEmails: string[]) => Yup.ObjectSchema<InitialValues>;

export const getValidationSchema: ValidationFn = (addedEmails) =>
  Yup.object().shape({
    memberEmail: Yup.string()
      .email(i18n.t('nonValidEmail') as string)
      .test({
        name: 'unique',
        exclusive: true,
        message: i18n.t('nonUniqueEmailError') as string,
        test: (value) => {
          if (value !== undefined && value.length > 0 && addedEmails.includes(value)) {
            return false;
          }

          return true;
        },
      })
      .test({
        name: 'empty',
        exclusive: true,
        message: i18n.t('emptyEmailsError') as string,
        test: (value) => {
          if (value === undefined && !addedEmails.length) {
            return false;
          }

          return true;
        },
      }),
    memberRole: Yup.string().required(i18n.t('requiredButton') as string),
  });
