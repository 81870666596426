import { takeLatest } from 'redux-saga/effects';

import {
  CANCEL_INVITE_DECISION_MAKER_SUCCESS,
  CANCEL_INVITE_TO_TEAM_SUCCESS,
  CHANGE_EMPLOYEE_ROLE_SUCCESS,
  CHANGE_EMPLOYEE_STATUS_SUCCESS,
  CREATE_TEAM_SUCCESS,
  DELETE_TEAM_SUCCESS,
  INVITE_DECISION_MAKER_SUCCESS,
  INVITE_USERS_SUCCESS,
  REINVITE_DECISION_MAKER_SUCCESS,
  UPDATE_TEAM_SUCCESS,
} from '../../../actions/teams';
import { onCancelInvite } from './onCancelInvite';
import { onChangeEmployeeRole } from './onChangeEmployeeRole';
import { onChangeEmployeeStatus } from './onChangeEmployeeStatus';
import { onCreateTeam } from './onCreateTeam';
import { onDeleteTeam } from './onDeleteTeam';
import { onInviteUsers } from './onInviteUsers';
import { onUpdateTeam } from './onUpdateTeam';
import { onUpdateDicisionMakers } from './onUpdateDicisionMakers';

export default function* fn() {
  yield takeLatest(CREATE_TEAM_SUCCESS.type, onCreateTeam);
  yield takeLatest(UPDATE_TEAM_SUCCESS.type, onUpdateTeam);
  yield takeLatest(CHANGE_EMPLOYEE_ROLE_SUCCESS.type, onChangeEmployeeRole);
  yield takeLatest(CHANGE_EMPLOYEE_STATUS_SUCCESS.type, onChangeEmployeeStatus);
  yield takeLatest(INVITE_USERS_SUCCESS.type, onInviteUsers);
  yield takeLatest(DELETE_TEAM_SUCCESS.type, onDeleteTeam);
  yield takeLatest(CANCEL_INVITE_TO_TEAM_SUCCESS.type, onCancelInvite);
  yield takeLatest(INVITE_DECISION_MAKER_SUCCESS.type, onInviteUsers);
  yield takeLatest(REINVITE_DECISION_MAKER_SUCCESS.type, onUpdateDicisionMakers);
  yield takeLatest(CANCEL_INVITE_DECISION_MAKER_SUCCESS.type, onUpdateDicisionMakers);
}
