import { AppDispatch, createAction } from '@reduxjs/toolkit';
import { ICandidateActions } from 'types/filters/candidates';

export const CHANGE_CANDIDATES_LIST_FILTERS = createAction<ICandidateActions, string>(
  'candidates/CHANGE_CANDIDATES_LIST_FILTERS',
);

export const changeCandidatesListFilters = (candidatesFilter: ICandidateActions) => (dispatch: AppDispatch) => {
  dispatch(CHANGE_CANDIDATES_LIST_FILTERS(candidatesFilter));
};
