import { InputAdornment, IconButton } from '@mui/material';

type AdornmentProps = {
  position: 'end' | 'start';
  btnAriaLabel?: string;
  toggleIcon?: boolean;
  handleClickShowValue?: () => void;
  mainIcon: JSX.Element;
  secondaryIcon?: JSX.Element;
  inputAdornmentProps?: object;
  iconButtonProps?: object;
  setHover?: (value: boolean) => void;
};

const Adornment: React.FC<AdornmentProps> = ({
  position,
  btnAriaLabel = '',
  toggleIcon = true,
  handleClickShowValue,
  mainIcon,
  secondaryIcon,
  inputAdornmentProps = {},
  iconButtonProps = {},
}) => {
  return (
    <InputAdornment position={position} {...inputAdornmentProps}>
      <IconButton aria-label={btnAriaLabel} onClick={handleClickShowValue} edge={position} {...iconButtonProps}>
        {toggleIcon ? mainIcon : secondaryIcon}
      </IconButton>
    </InputAdornment>
  );
};

export default Adornment;
