import { useState } from 'react';

import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../../redux/selectors/auth';
import { getSelectedTeam } from '../../../redux/selectors/teams';

import { Typography, Popover } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import ProfilesIcons from 'shared/profiles-icons';
import PopoverContent from 'components/users-section/user-profiles-popover';

import useStyles from './style';

type propTypes = {
  isSidebarOpen: boolean;
  mobile: boolean;
};

const ProfilesNavigation = ({ isSidebarOpen, mobile }: propTypes) => {
  const { classes } = useStyles({ isSidebarOpen, mobile });

  const user = useSelector(getCurrentUser);
  const teamName = useSelector(getSelectedTeam)?.name;
  // prettier-ignore
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div aria-describedby={id} className={classes.profilesContainer} onClick={handleClick}>
        <ProfilesIcons />
        {isSidebarOpen && (
          <>
            <div className={classes.profilesNames}>
              {teamName ? (
                <>
                  <Typography>{teamName}</Typography>
                  <Typography
                    className={classes.userName}
                  >{`${user?.firstNameLatin} ${user?.lastNameLatin}`}</Typography>
                </>
              ) : (
                <Typography>{`${user?.firstNameLatin} ${user?.lastNameLatin}`}</Typography>
              )}
            </div>
            <ArrowDropDownIcon style={{ color: '#FAFAFA', width: '24px', height: '24px' }} />
          </>
        )}
      </div>
      <Popover
        id={id}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={0}
        slotProps={{
          paper: {
            style: {
              boxShadow: '2px 4px 16px 0px rgba(0, 0, 0, 0.05)',
              borderRadius: 0,
              marginLeft: '10px',
              marginTop: '-10px',
            },
          },
        }}
      >
        <PopoverContent closePopover={handleClose} />
      </Popover>
    </div>
  );
};

export default ProfilesNavigation;
