import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
  return {
    popoverContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.grey[100]}`,
      maxHeight: '270px',
    },
    popoverContentContainer: {
      paddingBottom: '16px',
    },
    tooltip: {
      backgroundColor: theme.palette.background.paper,
      padding: '2px 6px',
      borderRadius: '0 4px 4px 4px',
      boxShadow: `0px 5px 15px 0px rgba(0, 0, 0, 0.08), 
          0px 15px 35px -5px rgba(17, 24, 38, 0.15), 
          0px 0px 0px 1px rgba(152, 161, 178, 0.10)`,
    },
    teams: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'center',
      alignSelf: 'stretch',
      padding: '16px 16px 16px 8px',
      height: '218px',
    },
    addTeamBtn: {
      width: '38px',
      height: '38px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(69, 43, 228, 0.1)',
      border: `2px dashed ${theme.palette.primary.main}`,
      borderRadius: '50%',
      margin: '2px 16px',
      fontSize: '1.75rem',
      cursor: 'pointer',
      transition: 'all 150ms linear',

      '&:hover': {
        boxShadow: '0 0 5px 2px rgba(69, 43, 228, 0.1)',
        transition: 'all 150ms linear',
      },
    },
    avatar: {
      width: '39px',
      height: '39px',
      fontSize: '1rem',
      border: `1px solid ${theme.palette.primary.contrastText}`,
      marginBottom: '8px',
      marginLeft: '8px',
      cursor: 'pointer',
      transition: 'all 150ms linear',

      '&:hover': {
        boxShadow: '0 0 5px 2px rgba(0, 0, 0, 0.1)',
        transition: 'all 150ms linear',
      },
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px',
      borderLeft: '1px solid #DADADA',
      alignSelf: 'stretch',
    },
    info: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    teamName: {
      fontWeight: 600,
      marginLeft: '16px',
    },
    divider: {
      marginTop: '16px',
    },
    navLink: {
      marginTop: '4px',
      border: `8px solid transparent`,
      minWidth: '235px',
      transition: 'all 150ms linear',

      '&:hover': {
        backgroundColor: 'rgba(69, 43, 228, 0.15)',
        transition: 'all 150ms linear',
      },
    },
    activeNavLink: {
      marginTop: '16px',
      backgroundColor: theme.palette.primary.main,
      opacity: 0.1,
    },
    settingsMenuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '1.5rem',
      cursor: 'pointer',
    },
    logoutMenuItem: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'all 150ms linear',

      '&:hover': {
        backgroundColor: 'rgba(253, 79, 79, 0.15)',
        transition: 'all 150ms linear',
      },
    },
    logoutText: {
      marginLeft: '8px',
    },
  };
});

export default useStyles;
