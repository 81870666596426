import { PayloadAction } from '@reduxjs/toolkit';
import { getCommentsForCandidate } from '../../../actions/candidateComments';
import { store } from '../../../index';
import { ICandidateComment } from 'types/api/candidates';

export function* onGetCommentsForCandidate({ payload }: PayloadAction<ICandidateComment>) {
  const { dispatch } = store;
  dispatch(getCommentsForCandidate(payload?.candidateId));
  yield true;
}
