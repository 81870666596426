import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
      height: '100vh',

      [theme.breakpoints.down('mobile')]: {
        padding: '0 16px 16px 16px',
      },
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      padding: '40px 48px 0 48px',

      [theme.breakpoints.down('mobile')]: {
        alignItems: 'center',
        padding: '16px 0 0 0',
      },
    },
    navigation: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    logo: {
      '& > path': {
        fill: '#000',
      },
    },
    helperText: {
      marginRight: '16px',
      letterSpacing: '0.32px',
      [theme.breakpoints.down('mobile')]: {
        display: 'none',
      },
    },
    childrenContainer: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    navButton: {
      textTransform: 'capitalize',

      [theme.breakpoints.down('mobile')]: {
        padding: '14px 32px',
      },
    },
  };
});

export default useStyles;
