import { getAllCandidates } from 'redux/actions/candidates';
import { store } from '../../../index';
import { PayloadAction } from '@reduxjs/toolkit';
import { IVacancies } from 'types/api/vacancies';

export function* onChangeCandidateStatus({ payload }: PayloadAction<IVacancies>) {
  const { dispatch } = store;

  dispatch(
    getAllCandidates({
      filters: {
        filter: {
          vacancy: payload?._id,
        },
      },

      onFailure: () => {},
    }),
  );
  yield true;
}
