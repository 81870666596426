import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as OmiteamLogo } from '../../../svg/authorizationSection/omi-team.svg';

import useStyles from './styles';
import theme from 'theme/theme';

type AuthorizationHeaderProps = {
  children: React.JSX.Element;
};

const AuthorizationHeader: React.FC<AuthorizationHeaderProps> = ({ children }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const location = useLocation();
  const isSignUpPage = location.pathname.split('/')[1] === 'signup';

  return (
    <main className={classes.container}>
      <header className={classes.headerContainer}>
        <Link to='/'>
          <OmiteamLogo fill={theme.palette.text.primary} />
        </Link>
        <nav className={classes.navigation}>
          <Typography variant='h4' className={classes.helperText}>
            {isSignUpPage ? t('alreadyHaveAccountQuestion') : t('dontHaveAccountQuestion')}
          </Typography>
          <Link color='inherit' to={isSignUpPage ? '/' : '/signup'}>
            <Button variant='contained' className={classes.navButton}>
              {isSignUpPage ? t('signInTitle') : t('signUpTitle')}
            </Button>
          </Link>
        </nav>
      </header>
      <div className={classes.childrenContainer}>{children}</div>
    </main>
  );
};

export default AuthorizationHeader;
