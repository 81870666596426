import { Dispatch, SetStateAction } from 'react';

export const horizontalScroll = (
  element: HTMLDivElement | null,
  speed: number,
  distance: number,
  step: number,
  showLeftScrollArrow: Dispatch<SetStateAction<boolean>>,
  showRightScrollArrow: Dispatch<SetStateAction<boolean>>,
) => {
  let scrollAmount = 0;

  const slideTimer = setInterval(() => {
    element!.scrollLeft += step;

    scrollAmount += Math.abs(step);

    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }

    if (element!.scrollLeft === 0) {
      showLeftScrollArrow(false);
    } else {
      showLeftScrollArrow(true);
    }

    if (Math.ceil(element!.scrollLeft + element!.offsetWidth) >= element!.scrollWidth) {
      showRightScrollArrow(false);
    } else {
      showRightScrollArrow(true);
    }
  }, speed);
};

export const verticalScroll = (
  element: HTMLDivElement | null,
  speed: number,
  distance: number,
  step: number,
  showUpScrollArrow: Dispatch<SetStateAction<boolean>>,
  showDownScrollArrow: Dispatch<SetStateAction<boolean>>,
) => {
  let scrollAmount = 0;

  const slideTimer = setInterval(() => {
    element!.scrollTop += step;

    scrollAmount += Math.abs(step);

    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }

    if (element!.scrollTop === 0) {
      showUpScrollArrow(false);
    } else {
      showUpScrollArrow(true);
    }

    if (Math.ceil(element!.scrollTop + element!.offsetHeight) >= element!.scrollHeight) {
      showDownScrollArrow(false);
    } else {
      showDownScrollArrow(true);
    }
  }, speed);
};
