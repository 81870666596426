import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Typography, Divider, Avatar } from '@mui/material';
import { ReactComponent as LogoutIcon } from '../../../svg/sidebar/logout.svg';

import { prevPageUrlStorage } from 'helpers/storageFunctions';

import useStyles from './style';
import { useSelector } from 'react-redux';
import { getSelectedTeam } from '../../../redux/selectors/teams';

type UserProfileMenuProps = {
  currentUser: any;
  handleLogoutClick: () => void;
  closePopover?: (event: React.MouseEvent<any>) => void;
};

const UserProfileMenu: React.FC<UserProfileMenuProps> = ({ currentUser, handleLogoutClick, closePopover }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const teamId = useSelector(getSelectedTeam)?._id;

  const amazonUrl = `${process.env.REACT_APP_AMAZON_URL}/${teamId}/avatars/`;
  const userNameFirstLetter = currentUser?.firstNameLatin.split('', 1).join('');
  const userLastNameFirstLetter = currentUser?.lastNameLatin.split('', 1).join('');

  return (
    <div className={classes.section}>
      <div className={classes.info}>
        <Avatar alt='avatar' src={amazonUrl + currentUser?.currentAvatar} className={classes.avatar}>
          {userNameFirstLetter + userLastNameFirstLetter}
        </Avatar>
        <Typography
          className={classes.teamName}
        >{`${currentUser?.firstNameLatin} ${currentUser?.lastNameLatin}`}</Typography>
      </div>
      <Divider className={classes.divider} />
      <nav>
        <Link to='/profile/details'>
          <Typography
            className={classes.navLink}
            onClick={(event) => {
              closePopover?.(event);
              prevPageUrlStorage().setPrevPageUrl([]);
            }}
          >
            {t('userSettingsText')}
          </Typography>
        </Link>
        <Link to='#' className={classes.disabledLink}>
          <Typography
            className={classes.disabledNavLink}
            onClick={(event) => {
              closePopover?.(event);
              prevPageUrlStorage().setPrevPageUrl([]);
            }}
          >
            {t('userHelpLinkText')}
          </Typography>
        </Link>
      </nav>
      <Divider className={classes.divider} />
      <div className={`${classes.navLink} ${classes.logoutMenuItem}`} onClick={handleLogoutClick}>
        <LogoutIcon />
        <Typography className={classes.logoutText}>{t('logoutLabel')}</Typography>
      </div>
    </div>
  );
};

export default UserProfileMenu;
