import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IVacanciesByCandidateResponse, IVacancyByCandidate } from 'types/api/vacancies';
import { ICandidateActions } from 'types/filters/candidates';
import { IVacancyByCandidateFilters } from 'types/filters/vacancies';
import { LOADING } from '../../assets/loading';
import { ICandidates, IGetAllCandidates } from '../../types/api/candidates';
import {
  CLEAR_CANDIDATES,
  CLEAR_SELECTED_CANDIDATE,
  GET_ALL_CANDIDATES_FAILURE,
  GET_ALL_CANDIDATES_PENDING,
  GET_ALL_CANDIDATES_SUCCESS,
  GET_ALL_VACANCIES_BY_CANDIDATES_FAILURE,
  GET_ALL_VACANCIES_BY_CANDIDATES_PENDING,
  GET_ALL_VACANCIES_BY_CANDIDATES_SUCCESS,
  GET_CANDIDATE_BY_ID_SUCCESS,
  SET_VACANCIES_BY_CANDIDATE_PAGE,
} from '../actions/candidates';
import { CHANGE_CANDIDATES_LIST_FILTERS } from '../actions/filters/candidatesFilter';

type InitialState = {
  selectedCandidate: ICandidates | null;
  vacanciesSelectedCandidate: {
    vacancies: IVacancyByCandidate | [];
    page: number;
    totalLength: number | null;
  };
  filtersVacanciesSelectedCandidate: IVacancyByCandidateFilters;
  candidates: ICandidates[];
  offset: number | null;
  totalLength: number | null;
  page: number | null;
  loading: string | null;
  vacanciesLoading: string | null;
  filters: ICandidateActions;
};

const initialState: InitialState = {
  selectedCandidate: null,
  vacanciesSelectedCandidate: {
    vacancies: [],
    page: 1,
    totalLength: null,
  },
  filtersVacanciesSelectedCandidate: {
    sortByField: 'title',
    sortDirection: 'up',
  },
  candidates: [],
  page: null,
  offset: null,
  totalLength: null,
  loading: null,
  vacanciesLoading: null,
  filters: {
    searchByName: '',
    filter: {
      candidateTag: [],
      speciality: [],
      grade: '',
      client: '',
      vacancy: '',
      vacancyStatus: '',
    },
    column: {
      name: 'updatedAt',
      direction: 'down',
    },
  },
};

const handlers = {
  [GET_ALL_CANDIDATES_SUCCESS.type]: (prevState: InitialState, { payload }: PayloadAction<IGetAllCandidates>) => {
    return {
      ...prevState,
      candidates: payload.candidates,
      page: payload.page,
      totalLength: payload.totalLength,
      loading: LOADING.SUCCESS,
    };
  },
  [GET_ALL_CANDIDATES_PENDING.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      loading: LOADING.PENDING,
    };
  },
  [GET_ALL_CANDIDATES_FAILURE.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      loading: LOADING.FAILURE,
    };
  },
  [GET_CANDIDATE_BY_ID_SUCCESS.type]: (prevState: InitialState, { payload }: PayloadAction<ICandidates>) => {
    return {
      ...prevState,
      selectedCandidate: payload,
    };
  },
  [CHANGE_CANDIDATES_LIST_FILTERS.type]: (prevState: InitialState, { payload }: PayloadAction<ICandidateActions>) => {
    return {
      ...prevState,
      filters: payload,
    };
  },
  [CLEAR_SELECTED_CANDIDATE.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      selectedCandidate: null,
    };
  },

  [GET_ALL_VACANCIES_BY_CANDIDATES_SUCCESS.type]: (
    prevState: InitialState,
    { payload }: PayloadAction<IVacanciesByCandidateResponse>,
  ) => {
    return {
      ...prevState,
      vacanciesSelectedCandidate: {
        ...prevState.vacanciesSelectedCandidate,
        vacancies: payload.vacancies,
        totalLength: payload.totalLength,
      },
      vacanciesLoading: LOADING.SUCCESS,
    };
  },
  [GET_ALL_VACANCIES_BY_CANDIDATES_PENDING.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      vacanciesLoading: LOADING.PENDING,
    };
  },
  [GET_ALL_VACANCIES_BY_CANDIDATES_FAILURE.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      vacanciesLoading: LOADING.FAILURE,
    };
  },

  [SET_VACANCIES_BY_CANDIDATE_PAGE.type]: (prevState: InitialState, { payload }: PayloadAction<number>) => {
    return {
      ...prevState,
      vacanciesSelectedCandidate: {
        ...prevState.vacanciesSelectedCandidate,
        page: payload,
      },
    };
  },

  [CLEAR_CANDIDATES.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      candidates: [],
    };
  },
};

// @ts-ignore
export default createReducer(initialState, handlers);
