import { AppDispatch, createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';
import { IVacancyCandidateFilters, IVacancyFilters } from '../../types/filters/vacancies';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { VACANCIES_STATUSES } from '../../assets/vacanciesStatuses';

type FetchAllVacanciesActionType = {
  filters?: IVacancyFilters;
  page?: string;
  offset?: string;
  onFailure?: (error: string) => void;
  onSuccess?: (data: any) => void;
};
export const GET_ALL_VACANCIES_PENDING = createAction('vacancies/GET_ALL_VACANCIES.PENDING');
export const GET_ALL_VACANCIES_SUCCESS = createAction('vacancies/GET_ALL_VACANCIES.SUCCESS');
export const fetchAllVacancies =
  ({ filters, page, offset, onFailure, onSuccess }: FetchAllVacanciesActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(GET_ALL_VACANCIES_PENDING());
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `vacancies`,
        qsParams: {
          page: page ?? null,
          offset: offset ?? null,
          client: filters?.client ?? null,
          assignedTo: filters?.assignedTo ?? null,
          sortDirection: filters?.sortDirection ?? null,
          searchValue: filters?.searchValue ?? null,
          sortByField: filters?.sortByField ?? null,
          status: filters?.status ?? VACANCIES_STATUSES,
        },
      });
      dispatch(GET_ALL_VACANCIES_SUCCESS(data));
      onSuccess?.(data);
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure?.(errorMessage);
    }
  };

type GetVacancyByStatusActionType = {
  filters?: IVacancyFilters;
  onFailure?: (error: string) => void;
  onSuccess?: (data: any) => void;
};

export const GET_VACANCIES_BY_STATUS_PENDING = createAction('vacancies/GET_VACANCIES_BY_STATUS.PENDING');
export const GET_VACANCIES_BY_STATUS_SUCCESS = createAction('vacancies/GET_VACANCIES_BY_STATUS.SUCCESS');
export const GET_VACANCIES_BY_STATUS_FAILURE = createAction('vacancies/GET_VACANCIES_BY.FAILURE');
export const getVacancyByStatus =
  ({ filters, onFailure }: GetVacancyByStatusActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(GET_VACANCIES_BY_STATUS_PENDING());
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: 'vacancies/statusLengths',
        qsParams: {
          client: filters?.client ?? null,
          assignedTo: filters?.assignedTo ?? null,
          searchValue: filters?.searchValue ?? null,
        },
      });

      dispatch(GET_VACANCIES_BY_STATUS_SUCCESS(data));
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure?.(errorMessage);
      dispatch(GET_VACANCIES_BY_STATUS_FAILURE(errorMessage));
    }
  };

type FetchVacancyByIdActionType = {
  vacancyId: string;
  filters?: IVacancyCandidateFilters;
  onFailure?: (error: string) => void;
  onSuccessVacancy?: (data: any) => void;
};
export const GET_VACANCY_BY_ID_SUCCESS = createAction('vacancies/GET_VACANCY_BY_ID.SUCCESS');
export const fetchVacancyById =
  ({ vacancyId, filters, onSuccessVacancy, onFailure }: FetchVacancyByIdActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(GET_ALL_VACANCIES_PENDING());
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `vacancies/${vacancyId}`,
        qsParams: {
          speciality: filters?.speciality || null,
          grade: filters?.grade || null,
          availability: filters?.availability || null,
        },
      });
      onSuccessVacancy?.(data);
      dispatch(GET_VACANCY_BY_ID_SUCCESS(data));
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure?.(errorMessage);
    }
  };

type CreateVacancyActionType = {
  vacancyData: any;
  onSuccess?: () => void;
  onFailure?: (error: string) => void;
};
export const CREATE_VACANCY_SUCCESS = createAction('vacancies/CREATE_VACANCY.SUCCESS');
export const CREATE_VACANCY_FAILURE = createAction('vacancies/CREATE_VACANCY.FAILURE');
export const createVacancy =
  ({ vacancyData, onSuccess, onFailure }: CreateVacancyActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: 'vacancies',
        query: vacancyData,
      });
      dispatch(CREATE_VACANCY_SUCCESS(data));
      onSuccess?.();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      dispatch(CREATE_VACANCY_FAILURE(errorMessage));
      onFailure?.(errorMessage);
    }
  };

type EditVacancyActionType = {
  vacancyId: string;
  vacancyData: any;
  onSuccess?: () => void;
  onFailure?: (error: string) => void;
};
export const EDIT_VACANCY_SUCCESS = createAction('vacancies/EDIT_VACANCY.SUCCESS');
export const editVacancy =
  ({ vacancyId, vacancyData, onSuccess, onFailure }: EditVacancyActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: `vacancies/${vacancyId}`,
        query: vacancyData,
      });
      dispatch(EDIT_VACANCY_SUCCESS(data));
      onSuccess?.();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure?.(errorMessage);
    }
  };

type ChangeVacancyStatusActionType = {
  vacancyId: string;
  status: string;
  onSuccess?: (action?: string) => void;
  onFailure?: (error: string) => void;
};
export const CHANGE_VACANCY_STATUS_SUCCESS = createAction('vacancies/CHANGE_VACANCY_STATUS.SUCCESS');
export const CHANGE_VACANCY_STATUS_FAILURE = createAction('vacancies/CHANGE_VACANCY_STATUS.FAILURE');
export const changeVacancyStatus =
  ({ vacancyId, status, onSuccess, onFailure }: ChangeVacancyStatusActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: `vacancies/status/${vacancyId}`,
        query: { status },
      });
      dispatch(CHANGE_VACANCY_STATUS_SUCCESS(data));
      switch (status) {
        case 'OPEN':
          onSuccess?.('publish');
          break;
        case 'CLOSE_SUCCESSFUL':
          onSuccess?.('closeSuccessfuly');
          break;
        case 'CLOSE_UNSUCCESSFUL':
          onSuccess?.('closeUnsuccessfuly');
          break;
        case 'CANCELLED':
          onSuccess?.('cancel');
          break;
        default:
          onSuccess?.();
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      dispatch(CHANGE_VACANCY_STATUS_FAILURE(errorMessage));
      onFailure?.(errorMessage);
    }
  };

type CopyVacancyActionType = {
  vacancyId: string;
  onSuccess?: (action?: string) => void;
  onFailure?: (error: string) => void;
};

export const COPY_VACANCY_SUCCES = createAction('vacancies/COPY_VACANCY_SUCCES');
export const COPY_VACANCY_FAILURE = createAction('vacancies/COPY_VACANCY_FAILURE');
export const copyVacancy =
  ({ vacancyId, onSuccess, onFailure }: CopyVacancyActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: `vacancies/copy/${vacancyId}`,
      });

      dispatch(COPY_VACANCY_SUCCES(data));
      onSuccess?.('copy');
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      dispatch(COPY_VACANCY_FAILURE(errorMessage));
      onFailure?.(errorMessage);
    }
  };

type DeleteVacancyActionType = {
  vacancyId: string;
  onSuccess?: (action?: string) => void;
  onFailure?: (error: string) => void;
};
export const DELETE_VACANCY_SUCCESS = createAction('vacancies/DELETE_VACANCY.SUCCESS');
export const deleteVacancy =
  ({ vacancyId, onSuccess, onFailure }: DeleteVacancyActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(GET_ALL_VACANCIES_PENDING());
      const { data } = await ApiService.apiCall({
        method: 'DELETE',
        endpoint: `vacancies/${vacancyId}`,
      });
      dispatch(DELETE_VACANCY_SUCCESS(data));
      onSuccess?.('delete');
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure?.(errorMessage);
    }
  };

export const CLEAR_SELECTED_VACANCY = createAction('vacancies/CLEAR_SELECTED_VACANCY');
export const clearSelectedVacancy = () => (dispatch: AppDispatch) => {
  dispatch(CLEAR_SELECTED_VACANCY());
};

export const CHANGE_CURRENT_VACANCIES_PAGE = createAction<number, string>('vacancies/CHANGE_CURRENT_VACANCIES_PAGE');
export const changeCurrentVacanciesPage = (currentPage: number) => (dispatch: AppDispatch) => {
  dispatch(CHANGE_CURRENT_VACANCIES_PAGE(currentPage));
};

export const GET_VACANCIES_TEMPLATES_PENDING = createAction('vacancies/GET_VACANCIES_TEMPLATES.PENDING');
export const GET_VACANCIES_TEMPLATES_SUCCESS = createAction('vacancies/GET_VACANCIES_TEMPLATES.SUCCESS');
export const fetchVacanciesTemplates =
  ({ filters, page, offset, onFailure, onSuccess }: FetchAllVacanciesActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(GET_VACANCIES_TEMPLATES_PENDING());
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `vacancies`,
        qsParams: {
          page: page ?? null,
          offset: offset ?? null,
          client: filters?.client ?? null,
          assignedTo: filters?.assignedTo ?? null,
          sortDirection: filters?.sortDirection ?? null,
          searchValue: filters?.searchValue ?? null,
          sortByField: filters?.sortByField ?? null,
          status: filters?.status ?? null,
        },
      });
      dispatch(GET_VACANCIES_TEMPLATES_SUCCESS(data));
      onSuccess?.(data);
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure?.(errorMessage);
    }
  };
