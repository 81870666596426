import { Button, Dialog, DialogActions, DialogTitle, Divider, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { logOut } from '../../../redux/actions/auth';

import useStyles from './style';

type LogoutModalProps = {
  isOpen: boolean;
  toggleDialog: () => void;
};

const LogoutModal: React.FC<LogoutModalProps> = ({ isOpen, toggleDialog }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = useCallback(() => {
    dispatch(logOut());
    history.push('/');
  }, [dispatch]);

  return (
    <Dialog
      open={isOpen}
      onClose={toggleDialog}
      PaperProps={{
        style: {
          margin: 0,
        },
      }}
    >
      <div className={classes.dialogContainer}>
        <DialogTitle className={classes.dialogTitle}>{t('logoutLabel')}</DialogTitle>
        <Typography className={classes.dialogSubtitle}>{t('logoutModalTitle')}</Typography>
        <Divider variant='middle' className={classes.divider} />
        <DialogActions className={classes.dialogActions}>
          <Button onClick={toggleDialog} className={classes.button} variant='contained' color='secondary'>
            {t('cancelButton')}
          </Button>
          <Button className={classes.button} onClick={handleLogout} variant='contained' color='error'>
            {t('logoutLabel')}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default LogoutModal;
