import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ isSidebarOpen: boolean; mobile: boolean }>()(
  (theme, { isSidebarOpen, mobile }) => {
    return {
      profilesContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: isSidebarOpen && !mobile ? '32px 16px' : '32px 10px',
        padding: '20px 16px',
        border: '1px solid rgba(255, 255, 255, 0.15)',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'width 0.3s ease',
        width: isSidebarOpen && !mobile ? '240px' : 'fit-content',

        '&:hover': {
          backgroundColor: 'rgba(253, 253, 253, 0.10)',
          border: '1px solid rgba(255, 255, 255, 0.5)',
          transition: 'all 150ms linear',
        },

        [theme.breakpoints.down('mobile')]: {
          display: 'none',
        },
      },
      profilesNames: {
        marginLeft: '16px',
        marginRight: 'auto',
        transition: 'opacity 0.3s ease',
      },
      userName: {
        fontSize: '0.875rem',
        lineHeight: '130%',
        marginTop: '4px',
      },
    };
  },
);

export default useStyles;
