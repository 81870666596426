import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { tokenStorage } from '../../helpers/storageFunctions';
import { RoutesConstants } from 'constants/routesConstants';

interface IPublicRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  restricted?: boolean;
}

function PublicRoute({ component: Component, restricted, ...rest }: IPublicRouteProps) {
  const isUserLoggedIn = Boolean(tokenStorage().getAccessToken());
  return (
    <Route
      {...rest}
      render={(props) =>
        isUserLoggedIn && restricted ? <Redirect to={RoutesConstants.HOMEPAGE} /> : <Component {...props} />
      }
    />
  );
}

export default PublicRoute;
