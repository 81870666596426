import { font } from 'styles/commonStyles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ open: boolean; mobile: boolean }>()((theme, { open, mobile }) => {
  return {
    sidebarContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: open ? 'flex-start' : 'center',
      padding: '23px 0',
      color: theme.palette.secondary.main,
      boxSizing: 'border-box',
      height: '100dvh',
      width: open ? '304px' : '113px',
      transition: 'width 0.5s ease, alignItems 0.5s ease',
      backgroundColor: theme.palette.primary.main,
      '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#e1e1e11a',
        borderRadius: '5px',
      },
      [theme.breakpoints.down('mobile')]: {
        width: '100vw',
        minHeight: '100dvh',
        maxWidth: 'unset',
        padding: '84px 16px 16px',
        overflow: 'hidden',
      },
    },
    navLinksContainer: {
      padding: open ? '0 32px' : '0 16px',
      transition: 'padding 0.5s ease',
      [theme.breakpoints.down('mobile')]: {
        padding: '0',
        alignSelf: 'flex-start',
      },
    },
    logoContainer: {
      marginBottom: open ? '16px' : '16px',
      transition: 'margin 0.5s ease',
      [theme.breakpoints.down('mobile')]: {
        display: 'none',
      },
    },
    logo: {
      fill: theme.palette.primary.contrastText,
      width: open ? '126px' : '34px',
      transition: 'width 0.5s ease',
      [theme.breakpoints.up('mobile')]: {
        paddingTop: '8px',
        marginBottom: '16px',
      },
    },
    formControl: {
      '& .MuiInputBase-root': {
        border: '0px!important',
        borderRadius: 0,
        color: 'white',
        fontSize: '22px',
        fontWeight: 'bold',
        ...font,
        boxShadow: 'none',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
    },
    select: {
      '& .MuiSvgIcon-root': {
        color: 'white',
        width: '2em',
        height: '2em',
        top: 'calc(50% - 1em)',
      },
    },
    sidebarFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: mobile ? 'row' : open ? 'row' : 'column',
      transition: 'width 0.5s ease',
      width: open ? 'auto' : 'fit-content',
      alignItems: 'center',
      padding: open ? '0 24px' : '0 20px',
      marginTop: open ? '32px' : '0',
      rowGap: open ? '0' : '32px',
      [theme.breakpoints.down('mobile')]: {
        width: '100%',
        justifyContent: 'space-between',
        padding: '0',
        gap: 0,
        marginTop: 0,
        flexDirection: 'row',
      },
    },
    sidebarAppVersion: {
      color: 'rgba(253, 253, 253, 0.4)',
      [theme.breakpoints.up('mobile')]: {
        fontSize: '0.875rem',
      },
    },
    langContainer: {
      display: 'flex',
      flexDirection: open ? 'row' : 'column',
      width: open ? 'auto' : 'fit-content',
      transition: 'flex-direction 0.5s ease, width 0.5s ease',
      marginLeft: 'auto',
      cursor: 'pointer',
      columnGap: open ? '8px' : '0',
      rowGap: open ? '0' : '4px',
      [theme.breakpoints.down('mobile')]: {
        flexDirection: 'row',
        width: 'auto',
        columnGap: '8px',
      },
    },
    flagIcon: {
      marginRight: '4px',
    },
    bottomContainer: {
      [theme.breakpoints.down('mobile')]: {
        width: '100%',
      },
    },
    langBtn: {
      padding: '8px',
      backgroundColor: theme.palette.primary.main,
      textTransform: 'capitalize',
      borderRadius: '8px',
      transition: 'all 150ms linear',
      '&:hover': {
        border: '1px solid rgba(255, 255, 255, 0.55)',
        backgroundColor: 'rgba(253, 253, 253, 0.10)',
        boxShadow: 'none',
        borderRadius: '8px',
        transition: 'all 150ms linear',
      },
    },
    langPicked: {
      color: theme.palette.primary.contrastText,
      borderRadius: '8px',
      border: '1px solid rgba(255, 255, 255, 0.55)',
      backgroundColor: 'rgba(253, 253, 253, 0.10)',
    },
    langBtnText: {
      color: 'rgba(253, 253, 253, 0.5)',
      marginLeft: '4px',
      fontWeight: 600,
      [theme.breakpoints.up('mobile')]: {
        fontWeight: 500,
        fontSize: '0.875rem',
      },
    },
    langPickedBtnText: {
      color: theme.palette.primary.contrastText,
    },
    logoSmall: {
      fontSize: '24px',
      fontWeight: '700',
      width: 34,
      height: 34,
      margin: 'auto',
      color: 'white',
    },
  };
});

export default useStyles;
