import { PayloadAction, createReducer } from '@reduxjs/toolkit';

import { GET_CANDIDATES_HISTORY_BY_ID_SUCCESS, CLEAR_CANDIDATE_HISTORY } from '../actions/history';
import { ICandidateHistory } from '../../types/api/candidates';

type InitialState = {
  candidatHistory: ICandidateHistory[];
  totalLength: number | null;
};

const initialState: InitialState = {
  candidatHistory: [],
  totalLength: null,
};

const handlers = {
  [GET_CANDIDATES_HISTORY_BY_ID_SUCCESS.type]: (
    prevState: InitialState,
    { payload }: PayloadAction<{ candidateHistory: ICandidateHistory[]; totalLength: number }>,
  ) => {
    return {
      ...prevState,
      candidatHistory: payload?.candidateHistory,
      totalLength: payload?.totalLength,
    };
  },

  [CLEAR_CANDIDATE_HISTORY.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      candidatHistory: [],
    };
  },
};

export default createReducer(initialState, handlers);
