import { CurrentUser } from 'types/api/users';
import { PayloadAction } from '@reduxjs/toolkit';
import { selectedTeamStorage } from 'helpers/storageFunctions';
import { store } from '../../../index';
import { getTeamById } from '../../../actions/teams';

function* onGetCurrentUser(action: PayloadAction<CurrentUser>) {
  const { dispatch } = store;
  const currentUser = action.payload;
  const teamInLocalStorage = selectedTeamStorage().getSelectedTeam();

  if (teamInLocalStorage && !currentUser.teams.length) {
    selectedTeamStorage().setSelectedTeam(null);
    return;
  }

  if (!teamInLocalStorage && currentUser.teams.length) {
    selectedTeamStorage().setSelectedTeam(currentUser.teams[0]._id);
  }

  dispatch(getTeamById({}));

  yield true;
}

export default onGetCurrentUser;
