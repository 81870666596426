import { font } from 'styles/commonStyles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ open: boolean; mobile: boolean; isActive: boolean }>()(
  (theme, { open, mobile, isActive }) => {
    return {
      toggleButton: {
        position: 'absolute',
        top: '30px',
        left: open ? `${282}px` : '93px',
        backgroundColor: 'white',
        zIndex: theme.zIndex.drawer + 1,
        border: '1px solid lightgray',
        color: '#452BE4',
        transition: 'left 0.5s ease !important',
        '&:hover': {
          backgroundColor: 'white',
          boxShadow: 'none',
          transition: 'all 150ms linear',
        },
      },

      mainContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',

        [theme.breakpoints.down('mobile')]: {
          flexDirection: 'column',
        },
      },
      mobileSidebar: {
        display: 'none',

        [theme.breakpoints.down('mobile')]: {
          display: 'block',
          zIndex: 1500,
        },
      },
      desktopSidebar: {
        display: 'block',

        [theme.breakpoints.down('mobile')]: {
          display: 'none',
        },
      },
      mobileNavbar: {
        display: 'none',
        [theme.breakpoints.down('mobile')]: {
          display: 'flex',
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          padding: '24px 16px',
          alignItems: 'center',
          width: '100vw',
          height: '100vh',
          maxHeight: '84px',
          boxSizing: 'border-box',
        },
      },
      menuBtn: {
        padding: 0,
        fontSize: '2rem',
        color: theme.palette.primary.contrastText,
      },
      logo: {
        fill: theme.palette.primary.contrastText,
      },
      leftSide: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 'auto',
      },
      logoContainer: {
        height: '84px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('mobile')]: {
          display: 'none',
        },
      },
      formControl: {
        '& .MuiInputBase-root': {
          border: '0px!important',
          borderRadius: 0,
          color: 'white',
          fontSize: '22px',
          fontWeight: 'bold',
          ...font,
          boxShadow: 'none',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
      },
      select: {
        '& .MuiSvgIcon-root': {
          color: 'white',
          width: '2em',
          height: '2em',
          top: 'calc(50% - 1em)',
        },
      },
      icon: {
        fill: 'var(--secondary-text-color)',
        flexShrink: 0,

        [theme.breakpoints.down('mobile')]: {
          height: '36px',
          width: '36px',
        },
      },
      mobileSidebarWrap: {
        opacity: isActive ? 1 : 0,
        transition: 'opacity 0.5s ease',
      },
    };
  },
);
