import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '10px',
      maxHeight: '180px',

      [theme.breakpoints.down('mobile')]: {
        flexDirection: 'row',
        maxWidth: '100vw',
        padding: '0 10px 10px',
      },
    },
    emptyContainer: {
      padding: 0,
      paddingTop: '16px',
    },
    avatarsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontSize: '1.5rem',
      cursor: 'pointer',
      overflowX: 'auto',

      '&::-webkit-scrollbar': {
        display: 'none',
      },

      [theme.breakpoints.down('mobile')]: {
        flexDirection: 'row',
        maxWidth: '100vw',
      },
    },
    avatar: {
      width: '39px',
      height: '39px',
      fontSize: '1rem',
      border: `1px solid ${theme.palette.primary.contrastText}`,
      backgroundColor: theme.palette.primary.main,
      margin: '6px',
      opacity: 0.1,
      transition: 'all 150ms linear',

      '&:hover': {
        boxShadow: '0 0 5px 2px rgb(0, 0, 0, 0.5)',
        transition: 'all 150ms linear',
      },
    },
    activeAvatar: {
      boxShadow: '0 0 5px 2px rgba(69, 43, 228, 0.15)',
      opacity: 1,
      transition: 'all 150ms linear',

      '&:hover': {
        boxShadow: '0 0 5px 2px rgba(0, 0, 0, 0.2)',
        transition: 'all 150ms linear',
      },
    },
    teamItemContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',

      '&:not(:last-child)': {
        marginBottom: '4px',
        marginRight: 0,

        [theme.breakpoints.down('mobile')]: {
          marginRight: '6px',
          marginBottom: 0,
        },
      },
    },
    leftArrow: {
      cursor: 'pointer',

      [theme.breakpoints.down('mobile')]: {
        transform: 'rotate(-90deg)',
        marginRight: '8px',
      },
    },
    rightArrow: {
      transform: 'rotate(180deg)',
      cursor: 'pointer',

      [theme.breakpoints.down('mobile')]: {
        transform: 'rotate(90deg)',
        marginLeft: '8px',
      },
    },
  };
});

export default useStyles;
