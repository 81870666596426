import authSaga from './Authorization';
import usersSaga from './Users';
import candidateCommentsSaga from './CandidateComments';
import userReviewsSaga from './UserReviews';
import candidateRemindersSaga from './CandidateReminders';
import vacanciesSaga from './Vacancies';
import specializationsSaga from './Specializations';
import channelsSaga from './Channels';
import teamsSaga from './Teams';
import tagsSaga from './Tags';
import candidatesSaga from './Candidates';
import clientsSaga from './Clients';
import { SagaMiddleware } from 'redux-saga';

const fn = ({ run }: SagaMiddleware<object>) => {
  run(authSaga);
  run(usersSaga);
  run(candidateCommentsSaga);
  run(userReviewsSaga);
  run(candidateRemindersSaga);
  run(vacanciesSaga);
  run(specializationsSaga);
  run(channelsSaga);
  run(teamsSaga);
  run(tagsSaga);
  run(candidatesSaga);
  run(clientsSaga);
};

export default fn;
