import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_CLOSED_VACANCIES_STATISTICS_SUCCESS = createAction(
  'statistics/GET_CLOSED_VACANCIES_STATISTICS.SUCCESS',
);
export const fetchClosedVacanciesStatistics =
  (startDate = '', endDate = '', grade = '', specialization = '') =>
  async (dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: 'statistics/closed-vacancies',
        qsParams: {
          startDate: startDate || null,
          endDate: endDate || null,
          grade: grade || null,
          specialization: specialization || null,
        },
      });
      dispatch(GET_CLOSED_VACANCIES_STATISTICS_SUCCESS(data));
    } catch (err) {}
  };

export const GET_HIRED_EMPLOYEES_STATISTICS_SUCCESS = createAction('statistics/GET_HIRED_EMPLOYEES_STATISTICS.SUCCESS');
export const fetchHiredEmployeesStatistics =
  (startDate = '', endDate = '') =>
  async (dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: 'statistics/hired-employees',
        qsParams: {
          startDate: startDate || null,
          endDate: endDate || null,
        },
      });
      dispatch(GET_HIRED_EMPLOYEES_STATISTICS_SUCCESS(data));
    } catch (err) {}
  };

export const GET_FIRED_EMPLOYEES_STATISTICS_SUCCESS = createAction('statistics/GET_FIRED_EMPLOYEES_STATISTICS.SUCCESS');
export const fetchFiredEmployeesStatistics =
  (startDate = '', endDate = '') =>
  async (dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: 'statistics/fired-employees',
        qsParams: {
          startDate: startDate || null,
          endDate: endDate || null,
        },
      });
      dispatch(GET_FIRED_EMPLOYEES_STATISTICS_SUCCESS(data));
    } catch (err) {}
  };

export const GET_CANDIDATES_STATISTICS_BY_VACANCY_ID_SUCCESS = createAction(
  'candidatesStatistics/GET_CANDIDATES_STATISTICS_BY_VACANCY_ID.SUCCESS',
);
export const GET_CANDIDATES_STATISTICS_BY_VACANCY_ID_FAILURE = createAction(
  'candidatesStatistics/GET_CANDIDATES_STATISTICS_BY_VACANCY_ID.FAILURE',
);
export const fetchCandidatesStatisticsByVacancyId =
  (vacancyId = '') =>
  async (dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `candidates-statistics/${vacancyId}`,
      });
      dispatch(GET_CANDIDATES_STATISTICS_BY_VACANCY_ID_SUCCESS(data));
    } catch (err) {
      dispatch(GET_CANDIDATES_STATISTICS_BY_VACANCY_ID_FAILURE(err));
    }
  };

export const GET_VACATIONS_STATISTICS_SUCCESS = createAction('vacations/statistics/GET_VACATIONS_STATISTICS.SUCCESS');
export const fetchVacationsStatistics =
  (year = '', role = '') =>
  async (dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: 'vacations/statistics',
        qsParams: {
          startDate: year || null,
          role: role || null,
        },
      });
      dispatch(GET_VACATIONS_STATISTICS_SUCCESS(data));
    } catch (err) {}
  };

export const DELETE_FIRED_EMPLOYEES_STATISTICS_SUCCESS = createAction(
  'statistics/DELETE_FIRED_EMPLOYEES_STATISTICS.SUCCESS',
);
export const DELETE_FIRED_EMPLOYEES_STATISTICS_FAILURE = createAction(
  'statistics/DELETE_FIRED_EMPLOYEES_STATISTICS.FAILURE',
);
export const deleteFiredEmployeesStatistics = (id, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'DELETE',
      endpoint: `statistics/fired-employees/${id}`,
    });
    dispatch(DELETE_FIRED_EMPLOYEES_STATISTICS_SUCCESS(data));
    onSuccess();
  } catch (err) {
    onFailure(err.response.data.error);
  }
};
export const DELETE_HIRED_EMPLOYEES_STATISTICS_SUCCESS = createAction(
  'statistics/DELETE_HIRED_EMPLOYEES_STATISTICS.SUCCESS',
);
export const DELETE_HIRED_EMPLOYEES_STATISTICS_FAILURE = createAction(
  'statistics/DELETE_HIRED_EMPLOYEES_STATISTICS.FAILURE',
);
export const deleteHiredEmployeesStatistics = (id, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'DELETE',
      endpoint: `statistics/hired-employees/${id}`,
    });
    dispatch(DELETE_HIRED_EMPLOYEES_STATISTICS_SUCCESS(data));
    onSuccess();
  } catch (err) {
    onFailure(err.response.data.error);
  }
};
export const GET_USER_BY_CANDIDATES_STATISTIC_ID_SUCCESS = createAction(
  'candidates-statistics/GET_USER_BY_CANDIDATES_STATISTIC_ID.SUCCESS',
);
export const getUsersByCandidatesStatisticId = (statisticId, status) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `candidates-statistics/statistics/${statisticId}`,
      qsParams: {
        status: status || null,
      },
    });
    dispatch(GET_USER_BY_CANDIDATES_STATISTIC_ID_SUCCESS(data));
  } catch (err) {}
};

export const CLEAR_CANDIDATES_STATISTICS = createAction('candidates-statistics/CLEAR_CANDIDATES_STATISTICS');

export const clearCandidatesStatistics = () => (dispatch) => {
  dispatch(CLEAR_CANDIDATES_STATISTICS());
};
