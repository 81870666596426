import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ isSidebarOpen: boolean }>()((theme, { isSidebarOpen }) => {
  return {
    navbarLink: {
      display: 'block',
      marginBottom: '16px',
      padding: isSidebarOpen ? '8px 0' : '10px',
      cursor: 'pointer',
      width: 'fit-content',

      '&::not(::last())': {
        marginBottom: '16px',
      },
    },
    accordeonNavbarLink: {
      marginBottom: '8px',
      padding: '4px 0',

      '&::not(::last())': {
        marginBottom: '8px',
      },
    },
  };
});

export default useStyles;
