import { store } from '../../../index';
import { getTeamById } from 'redux/actions/teams';

export function* onChangeEmployeeRole() {
  const { dispatch } = store;

  dispatch(getTeamById({}));

  yield true;
}
