import { fetchCurrentUser } from '../../../actions/auth';
import { store } from '../../../index';
import { PayloadAction } from '@reduxjs/toolkit';
import { ITeams } from 'types/api/teams';

export function* onUpdateTeam(action: PayloadAction<ITeams>) {
  const { dispatch } = store;

  dispatch(fetchCurrentUser());

  yield true;
}
