import { getCandidateById } from 'redux/actions/candidates';
import { store } from '../../../index';
import { ICandidates } from 'types/api/candidates';
import { PayloadAction } from '@reduxjs/toolkit';

export function* onUpdateCandidate({ payload }: PayloadAction<ICandidates>) {
  const { dispatch } = store;
  dispatch(getCandidateById({ candidateId: payload?._id }));
  yield true;
}
