import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { tokenStorage } from '../../helpers/storageFunctions';
import { RoutesConstants } from 'constants/routesConstants';

interface IPrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

function PrivateRoute({ component: Component, ...rest }: IPrivateRouteProps) {
  const isUserLoggedIn = Boolean(tokenStorage().getAccessToken());

  if (isUserLoggedIn && rest.location?.pathname === '/') {
    return <Redirect to={RoutesConstants.HOMEPAGE} />;
  }

  return isUserLoggedIn ? <Route {...rest} component={Component} /> : <Redirect to='/' />;
}

export default PrivateRoute;
