import { useState, useEffect } from 'react';

export function debounce(fn: () => void, ms: number) {
  let timer: string | number | undefined | NodeJS.Timeout;

  return () => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      timer = undefined;
      fn();
    }, ms);
  };
}

export const useWindowWidth = () => {
  const [dimensions, setDimensions] = useState(window.innerWidth);

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setDimensions(window.innerWidth);
    }, 300);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  return dimensions;
};
