import { takeLatest } from 'redux-saga/effects';

import { CREATE_CHANNEL_SUCCESS, UPDATE_CHANNEL_SUCCESS, DELETE_CHANNEL_SUCCESS } from '../../../actions/channels';

import { onGetChannels } from './onGetChannels';

export default function* fn() {
  yield takeLatest(CREATE_CHANNEL_SUCCESS.type, onGetChannels);

  yield takeLatest(UPDATE_CHANNEL_SUCCESS.type, onGetChannels);

  yield takeLatest(DELETE_CHANNEL_SUCCESS.type, onGetChannels);
}
