import React, { useState } from 'react';
import { ToggleButtonGroup, ToggleButton, Typography } from '@mui/material';

import { ToggleButtonsGroupProps } from 'types/components/toggleButtonsGroupTypes';
import CustomInput from 'shared/input';
import useStyles from './style';

const ToggleButtonsGroup: React.FC<ToggleButtonsGroupProps> = ({
  ariaLabel = 'controlled-toggle-buttons-group',
  buttonValues,
  buttonLabels,
  size,
  formikValue,
  onSelect,
  setIsOther,
  isError,
  error,
  isOtherSelected = false,
  otherInputLabel = '',
  otherInputName = '',
  otherInputType = 'text',
  otherInputIsError = false,
  otherInputErrorMsg = '',
  otherInputOnChange,
  otherInputOnBlur,
  buttonProps = '',
  buttonsGroupProps = '',
}) => {
  const { classes } = useStyles();
  const [value, setValue] = useState<string | null>(formikValue);

  const handleChange = (event: React.MouseEvent<HTMLElement>, selectedValue: string | null) => {
    if (setIsOther) {
      if (selectedValue === 'OTHER') {
        setIsOther(true);
      } else {
        setIsOther(false);
      }
    }

    setValue(selectedValue);
    onSelect(selectedValue);
  };

  return (
    <>
      <ToggleButtonGroup
        value={value}
        onChange={handleChange}
        className={`${classes.buttonsGroup} ${buttonsGroupProps} ${isError && classes.errorButtonGroup}`}
        aria-label={ariaLabel}
        size={size}
        exclusive
        style={isError ? { marginBottom: '8px' } : {}}
      >
        {buttonValues.map((value) => (
          <ToggleButton
            key={value}
            value={value}
            className={`${classes.button} ${buttonProps} ${isError && classes.errorButton}`}
          >
            <Typography variant='body1' className={classes.buttonText}>
              {buttonLabels[value]}
            </Typography>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {isOtherSelected && (
        <CustomInput
          defaultValue={''}
          onChange={otherInputOnChange ? otherInputOnChange : () => {}}
          onBlur={otherInputOnBlur ? otherInputOnBlur : () => {}}
          inputType={otherInputType}
          label={otherInputLabel}
          name={otherInputName}
          variant='outlined'
          margin='none'
          showErrorText={otherInputIsError}
          formikError={otherInputErrorMsg}
        />
      )}
      {isError && (
        <div className={classes.errorContainer}>
          <Typography className={classes.error}>{error}</Typography>
        </div>
      )}
    </>
  );
};

export default ToggleButtonsGroup;
