import React, { useEffect, useRef, useState } from 'react';

import { TextField, Typography } from '@mui/material';

import { useWindowWidth } from 'hooks/useWindowWidth';
import { InputProps } from '../../types/components/inputTypes';

import { styleConstants } from 'constants/styleConstants';
import useStyles from './styles';

const CustomInput: React.FC<InputProps> = ({
  defaultValue = '',
  onChange,
  inputType = 'text',
  label = '',
  name = '',
  variant = 'outlined',
  margin = 'none',
  size = 'medium',
  isRequired = false,
  onBlur,
  onFocus,
  onKeyDownPress,
  showErrorText = false,
  formikError = '',
  helperText = '',
  disabled = false,
  formControlStyle = '',
  inputLabelStyle = '',
  inputStyle = '',
  variantInputStyle = '',
  helperTextStyle = '',
  endAdornment = false,
  startAdornment = false,
  isReset = false,
  setIsReset,
  multiline = false,
  minRows = 2,
  min = 0,
  max,
  step = 1,
  searchValue,
  stepFlow = false,
  shortError = '',
  autoComplete = 'new-password',
  placeholder,
}) => {
  const { classes } = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [isInFocus, setIsInFocus] = useState(false);
  const dimensions = useWindowWidth();
  const nativeInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (searchValue !== undefined) {
      setInputValue(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (nativeInputRef.current) {
      nativeInputRef.current.parentElement?.classList.remove('Mui-disabled');
      nativeInputRef.current.removeAttribute('disabled');
      nativeInputRef.current.classList.remove('Mui-disabled');
    }
  }, [showErrorText]);

  useEffect(() => {
    if (isReset && setIsReset) {
      setInputValue('');
      onChange('');
      setIsReset(false);
    }
  }, [isReset]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setInputValue(value);
    onChange(value);
  };

  const enterPressHandle = (event: React.KeyboardEvent<Element>) => {
    if (onKeyDownPress && event.key === 'Enter' && !showErrorText && !formikError) {
      onKeyDownPress(event);
      setInputValue('');
    }
  };

  const shortFormikError = formikError.toString().length < 80;

  useEffect(() => {
    if (nativeInputRef.current) {
      if (!shortFormikError && formikError) {
        nativeInputRef.current.setCustomValidity(formikError);
      } else {
        nativeInputRef.current.setCustomValidity('');
      }
    }
  }, [formikError, shortFormikError]);

  const handleBlur = () => {
    setIsInFocus(false);

    if (nativeInputRef.current && formikError && !shortFormikError) {
      nativeInputRef.current.reportValidity();
    }
  };

  const handleFocus = () => {
    setIsInFocus(true);
  };

  const getHelperText = () => {
    return (
      <>
        {shortFormikError ? (
          <Typography className={`${classes.helperText} ${helperTextStyle}`} component='span' color='error'>
            {formikError}
          </Typography>
        ) : null}

        {stepFlow && (
          <>
            {!showErrorText ? (
              <Typography className={`${classes.helperText} ${helperTextStyle}`} component='span' color='#452BE4'>
                {helperText}
              </Typography>
            ) : (
              <Typography className={`${classes.helperText} ${helperTextStyle}`} component='span' color='error'>
                {shortError}
              </Typography>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <TextField
      className={`${classes.formControl} ${formControlStyle}`}
      variant={variant}
      size={dimensions < 750 ? 'medium' : size}
      fullWidth
      margin={margin}
      error={showErrorText}
      InputLabelProps={{
        htmlFor: `${name}-outlined-input`,
        className: `${classes.label} ${inputLabelStyle}`,
        style: !isInFocus && !inputValue ? { top: styleConstants.inputLabelOffset } : {},
      }}
      label={label}
      required={isRequired}
      multiline={multiline}
      minRows={minRows}
      inputRef={nativeInputRef}
      InputProps={{
        id: `${name}-outlined-input`,
        label: label,
        type: inputType,
        value: inputValue,
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
        onKeyDown: enterPressHandle,
        endAdornment: endAdornment,
        startAdornment: startAdornment,
        className: `${classes.variantedInput} ${variantInputStyle}`,
        disabled: disabled,
        'aria-describedby': `${name}-helper-text`,
        autoComplete: autoComplete,
      }}
      inputProps={{
        min: min,
        max: max,
        step: step,
        variant: variant,
        className: `${inputStyle} ${classes.input}`,
        sx: dimensions < 750 ? { padding: '12px 14px' } : {},
      }}
      helperText={getHelperText()}
      FormHelperTextProps={{
        id: `${name}-helper-text`,
        className: `${classes.helperText} ${helperTextStyle}`,
      }}
      placeholder={placeholder}
    />
  );
};

export default CustomInput;
