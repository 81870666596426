import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { LOADING } from 'assets/loading';
import { IVacancies } from 'types/api/vacancies';
import { IVacancyFilters } from 'types/filters/vacancies';
import { VACANCIES_STATUSES } from '../../assets/vacanciesStatuses';
import { CHANGE_VACANCY_FILTERS } from '../actions/filters/vacancyFilters';
import {
  CHANGE_CURRENT_VACANCIES_PAGE,
  CHANGE_VACANCY_STATUS_SUCCESS,
  CLEAR_SELECTED_VACANCY,
  COPY_VACANCY_FAILURE,
  COPY_VACANCY_SUCCES,
  CREATE_VACANCY_FAILURE,
  CREATE_VACANCY_SUCCESS,
  DELETE_VACANCY_SUCCESS,
  EDIT_VACANCY_SUCCESS,
  GET_ALL_VACANCIES_PENDING,
  GET_ALL_VACANCIES_SUCCESS,
  GET_VACANCIES_BY_STATUS_FAILURE,
  GET_VACANCIES_BY_STATUS_PENDING,
  GET_VACANCIES_BY_STATUS_SUCCESS,
  GET_VACANCIES_TEMPLATES_SUCCESS,
  GET_VACANCY_BY_ID_SUCCESS,
} from '../actions/vacancies';

type InitialState = {
  vacancies: IVacancies[];
  selectedVacancy: IVacancies | null;
  loadingStatusData: string | null;
  error: string | null;
  totalLength: string | null;
  vacanciesLengthInStatuses: {
    status: string;
    itemsLength: number;
  }[];
  filters: IVacancyFilters;
  currentPage: number;
  templates: [];
};

const initialState: InitialState = {
  vacancies: [],
  selectedVacancy: null,
  loadingStatusData: null,
  error: null,
  totalLength: null,
  vacanciesLengthInStatuses: [],
  filters: {
    searchValue: '',
    client: '',
    assignedTo: '',
    status: VACANCIES_STATUSES,
    sortByField: 'title',
    sortDirection: 'up',
  },
  currentPage: 1,
  templates: [],
};

const handleFailure = (prevState: InitialState, { payload }: PayloadAction<{ message: string }>) => {
  return {
    ...prevState,
    loading: LOADING.FAILURE,
    error: payload.message,
  };
};

const handlers = {
  [GET_ALL_VACANCIES_SUCCESS.type]: (
    prevState: InitialState,
    {
      payload,
    }: PayloadAction<{
      vacancies: IVacancies[];
      totalLength: string;
    }>,
  ) => {
    return {
      ...prevState,
      loading: LOADING.SUCCESS,
      vacancies: payload.vacancies,
      totalLength: payload.totalLength,
    };
  },

  [GET_ALL_VACANCIES_PENDING.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      loading: LOADING.PENDING,
    };
  },

  [GET_VACANCIES_BY_STATUS_PENDING.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      loadingStatusData: LOADING.PENDING,
    };
  },
  [GET_VACANCIES_BY_STATUS_SUCCESS.type]: (
    prevState: InitialState,
    {
      payload,
    }: PayloadAction<{
      vacanciesLengthInStatuses: {
        status: string;
        itemsLength: number;
      }[];
    }>,
  ) => {
    return {
      ...prevState,
      loadingStatusData: LOADING.SUCCESS,
      vacanciesLengthInStatuses: payload,
    };
  },

  [GET_VACANCIES_BY_STATUS_FAILURE.type]: (
    prevState: InitialState,
    { payload }: PayloadAction<{ message: string }>,
  ) => {
    return {
      ...prevState,
      loadingStatusData: LOADING.FAILURE,
      error: payload.message,
    };
  },

  [GET_VACANCY_BY_ID_SUCCESS.type]: (prevState: InitialState, { payload }: PayloadAction<IVacancies>) => {
    return {
      ...prevState,
      loading: LOADING.SUCCESS,
      selectedVacancy: payload,
    };
  },

  [EDIT_VACANCY_SUCCESS.type]: (prevState: InitialState) => {
    return {
      ...prevState,
    };
  },

  [CREATE_VACANCY_SUCCESS.type]: (prevState: InitialState, { payload }: PayloadAction<IVacancies>) => {
    const status = prevState?.vacancies[0]?.status;
    const vacancies = payload.status === status ? [payload, ...prevState.vacancies] : [...prevState.vacancies];
    return {
      ...prevState,
      vacancies,
    };
  },

  [COPY_VACANCY_SUCCES.type]: (prevState: InitialState, { payload }: PayloadAction<IVacancies>) => {
    const vacancies = [payload, ...prevState.vacancies];
    return {
      ...prevState,
      vacancies,
    };
  },

  [COPY_VACANCY_FAILURE.type]: handleFailure,

  [CREATE_VACANCY_FAILURE.type]: (prevState: InitialState, { payload }: PayloadAction<{ message: string }>) => {
    return {
      ...prevState,
      loading: LOADING.FAILURE,
      error: payload.message,
    };
  },

  [CHANGE_VACANCY_STATUS_SUCCESS.type]: (prevState: InitialState, { payload }: PayloadAction<IVacancies>) => {
    return {
      ...prevState,
      loading: LOADING.SUCCESS,
      vacancies: prevState.vacancies.map((vacancy) =>
        vacancy._id === payload._id ? { ...vacancy, status: payload.status } : vacancy,
      ),
    };
  },

  [DELETE_VACANCY_SUCCESS.type]: (prevState: InitialState, { payload }: PayloadAction<IVacancies>) => {
    return {
      ...prevState,
      vacancies: prevState.vacancies.filter((vacancy) => vacancy._id !== payload._id),
      loading: LOADING.SUCCESS,
    };
  },

  [CLEAR_SELECTED_VACANCY.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      selectedVacancy: null,
    };
  },

  [CHANGE_VACANCY_FILTERS.type]: (prevState: InitialState, { payload }: PayloadAction<IVacancyFilters>) => {
    return {
      ...prevState,
      filters: payload,
    };
  },

  [CHANGE_CURRENT_VACANCIES_PAGE.type]: (prevState: InitialState, { payload }: PayloadAction<number>) => {
    return {
      ...prevState,
      currentPage: payload,
    };
  },

  [GET_VACANCIES_TEMPLATES_SUCCESS.type]: (
    prevState: InitialState,
    { payload }: PayloadAction<{ vacancies: IVacancies[] }>,
  ) => {
    return {
      ...prevState,
      templates: payload.vacancies,
    };
  },
};

export default createReducer(initialState, handlers);
