import { AppDispatch, createAction } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { IGetAllReminders } from '../../types/api/candidates';
import { ApiService } from '../middleware/api';

type GetRemindersActionType = {
  filters?: {
    page?: string;
    offset?: string;
    candidateId?: string;
    searchByName?: string;
    createdBy?: string;
    date?: string;
    column?: {
      name: string;
      direction: 'up' | 'down';
    };
  };
  onFailure?: (error: string) => void;
  onSuccess?: (response: IGetAllReminders) => void;
};
export const GET_REMINDERS_SUCCESS = createAction('candidateReminders/GET_REMINDERS.SUCCESS');
export const GET_REMINDERS_PENDING = createAction('candidateReminders/GET_REMINDERS.PENDING');
export const getReminders =
  ({ filters, onFailure, onSuccess }: GetRemindersActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const sortedBy = filters?.column ? { [filters.column.name]: filters.column.direction === 'down' ? -1 : 1 } : null;
      dispatch(GET_REMINDERS_PENDING());
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `candidates/reminders/all`,
        qsParams: {
          page: filters?.page || null,
          offset: filters?.offset || null,
          candidateId: filters?.searchByName || null,
          authorId: filters?.createdBy || null,
          date: filters?.date || null,
          sortedBy: sortedBy ? JSON.stringify(sortedBy) : null,
        },
      });
      dispatch(GET_REMINDERS_SUCCESS(data));
      onSuccess?.(data);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      onFailure?.(errorMessage);
    }
  };

type GetUserRemindersActionType = {
  onFailure?: (error: string) => void;
};
export const GET_USER_REMINDERS_SUCCESS = createAction('candidateReminders/GET_USER_REMINDERS.SUCCESS');
export const getCurrentUserReminders =
  ({ onFailure }: GetUserRemindersActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `candidates/reminders/all-user-reminders`,
      });

      dispatch(GET_USER_REMINDERS_SUCCESS(data));
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      onFailure?.(errorMessage);
    }
  };

type GetCandidateRemindersActionType = {
  candidatesId: string;
  filters?: {
    page?: string;
    offset?: string;
  };
  onFailure?: (error: string) => void;
};
export const GET_CANDIDATE_REMINDERS_SUCCESS = createAction('candidateReminders/GET_CANDIDATE_REMINDERS.SUCCESS');
export const fetchCandidateReminders =
  ({ candidatesId, onFailure }: GetCandidateRemindersActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `candidates/reminders/candidates-reminders?candidateId=${candidatesId}`,
      });
      dispatch(GET_CANDIDATE_REMINDERS_SUCCESS(data));
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      onFailure?.(errorMessage);
    }
  };

export const GET_TODAY_REMINDERS_STATUS_SUCCESS = createAction('candidateReminders/GET_TODAY_REMINDERS_STATUS.SUCCESS');
export const getTodayRemindersStatus = () => async (dispatch: AppDispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `candidates/reminders/today-reminders`,
    });
    dispatch(GET_TODAY_REMINDERS_STATUS_SUCCESS(data));
  } catch (err) {}
};

type CreateReminderActionType = {
  reminderData: {
    candidateId: string;
    description: string;
    date: string | null;
  };
  onSuccess: (reminderData?: any) => void;
  onFailure: (error: string) => void;
  specialParameter?: {
    page?: string | undefined;
    offset?: string | undefined;
    userId?: string | undefined;
    date?: string | undefined;
  };
};
export const CREATE_REMINDER_SUCCESS = createAction('candidateReminders/CREATE_REMINDER.SUCCESS');
export const CREATE_REMINDER_FAILURE = createAction('candidateReminders/CREATE_REMINDER.FAILURE');
export const createReminder =
  ({ reminderData, onSuccess, onFailure }: CreateReminderActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: 'candidates/reminders/',
        query: reminderData,
      });
      dispatch(CREATE_REMINDER_SUCCESS(data));
      onSuccess();
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      dispatch(CREATE_REMINDER_FAILURE(errorMessage));
      onFailure(errorMessage);
    }
  };

type UpdateReminderActionType = {
  reminderData: {
    description: string;
    date: string | null;
  };
  reminderId: string;
  onSuccess: () => void;
  onFailure: (error: string) => void;
  specialParameter?: {
    page?: string | undefined;
    offset?: string | undefined;
    userId?: string | undefined;
    date?: string | undefined;
  };
};
export const UPDATE_REMINDER_SUCCESS = createAction('candidateReminders/UPDATE_REMINDER.SUCCESS');
export const UPDATE_REMINDER_FAILURE = createAction('candidateReminders/UPDATE_REMINDER.FAILURE');
export const updateReminder =
  ({ reminderData, reminderId, onSuccess, onFailure }: UpdateReminderActionType) =>
  async (dispatch: AppDispatch) => {
    const request = { ...reminderData, candidateId: reminderId };

    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: `candidates/reminders/${reminderId}`,
        query: request,
      });

      dispatch(UPDATE_REMINDER_SUCCESS(data));
      onSuccess();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      dispatch(UPDATE_REMINDER_FAILURE(errorMessage));
      onFailure(errorMessage);
    }
  };

type DeleteReminderActionType = {
  reminderId: string;
  onSuccess: () => void;
  onFailure: (error: string) => void;
  specialParameter?: {
    page?: string | undefined;
    offset?: string | undefined;
    userId?: string | undefined;
    date?: string | undefined;
  };
};
export const DELETE_REMINDER_SUCCESS = createAction('candidateReminders/DELETE_REMINDER.SUCCESS');
export const DELETE_REMINDER_FAILURE = createAction('candidateReminders/DELETE_REMINDER.FAILURE');
export const deleteReminder =
  ({ reminderId, onSuccess, onFailure }: DeleteReminderActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'DELETE',
        endpoint: `candidates/reminders/${reminderId}`,
      });
      dispatch(DELETE_REMINDER_SUCCESS(data));
      onSuccess();
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      dispatch(DELETE_REMINDER_FAILURE(errorMessage));
      onFailure(errorMessage);
    }
  };

export const CLEAR_CANDIDATE_REMINDERS = createAction('candidates/CLEAR_CANDIDATE_REMINDERS');
export const clearCandidateReminders = () => (dispatch: AppDispatch) => {
  dispatch(CLEAR_CANDIDATE_REMINDERS());
};

type MarkReminderAsRead = {
  reminderIds: string[];
  onSuccess: () => void;
  onFailure: (error: string) => void;
};

export const MARK_REMINDERS_AS_READ_SUCCESS = createAction('candidateReminders/MARK_REMINDERS_AS_READ.SUCCESS');
export const MARK_REMINDERS_AS_READ_FAILURE = createAction('candidateReminders/MARK_REMINDERS_AS_READ.FAILURE');

export const markRemindersAsRead =
  ({ reminderIds, onSuccess, onFailure }: MarkReminderAsRead) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PATCH',
        endpoint: 'candidates/reminders/mark-as-read',
        query: reminderIds,
      });
      dispatch(MARK_REMINDERS_AS_READ_SUCCESS(data));
      onSuccess();
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      dispatch(UPDATE_REMINDER_FAILURE(errorMessage));
      onFailure(errorMessage);
    }
  };
