import { PayloadAction } from '@reduxjs/toolkit';
import { getAllTags } from '../../../actions/tags';
import { store } from '../../../index';
import { ITags } from '../../../../types/api/tags';

export function* onGetTags({ payload }: PayloadAction<ITags>) {
  const { dispatch } = store;

  dispatch(getAllTags({ refersTo: payload.refersTo }));

  yield true;
}
