import { takeLatest } from 'redux-saga/effects';
import {
  CREATE_CANDIDATE_COMMENT_SUCCESS,
  DELETE_CANDIDATE_COMMENT_SUCCESS,
  UPDATE_CANDIDATE_COMMENT_SUCCESS,
} from '../../../actions/candidateComments';
import { onGetCommentsForCandidate } from './onGetCommentsForCandidate';

export default function* fn() {
  yield takeLatest(CREATE_CANDIDATE_COMMENT_SUCCESS.type, onGetCommentsForCandidate);
  yield takeLatest(DELETE_CANDIDATE_COMMENT_SUCCESS.type, onGetCommentsForCandidate);
  yield takeLatest(UPDATE_CANDIDATE_COMMENT_SUCCESS.type, onGetCommentsForCandidate);
}
