import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
function Notification({ error, success, isOpen, onClose }) {
  const severity = error ? 'error' : 'success';
  const message = error || success;

  return message ? (
    <Snackbar
      open={isOpen}
      autoHideDuration={1500}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <MuiAlert elevation={6} variant='filled' onClose={onClose} severity={severity}>
        {message}
      </MuiAlert>
    </Snackbar>
  ) : null;
}

Notification.propTypes = {
  error: PropTypes.string,
  success: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Notification;
