import { PayloadAction, createReducer } from '@reduxjs/toolkit';

import {
  GET_ALL_SPECIALIZATIONS_SUCCESS,
  CREATE_SPECIALIZATIONS_SUCCESS,
  CREATE_SPECIALIZATIONS_FAILURE,
  UPDATE_SPECIALIZATION_SUCCESS,
  DELETE_SPECIALIZATION_SUCCESS,
} from '../actions/specializations';
import { ISpecialities } from 'types/api/specialities';

type InitialState = {
  specializations: ISpecialities[];
  error: string | null;
};

const initialState: InitialState = {
  specializations: [],
  error: null,
};

const handlers = {
  [GET_ALL_SPECIALIZATIONS_SUCCESS.type]: (prevState: InitialState, { payload }: PayloadAction<ISpecialities[]>) => {
    return {
      ...prevState,
      specializations: payload,
    };
  },

  [UPDATE_SPECIALIZATION_SUCCESS.type]: (prevState: InitialState, { payload }: PayloadAction<ISpecialities>) => {
    const copiedSpecializations = [...prevState.specializations];
    const replacedSpecializations = copiedSpecializations.map((spec) => {
      if (spec._id === payload._id) {
        spec = payload;
      }
      return spec;
    });

    return {
      ...prevState,
      specializations: [...replacedSpecializations],
    };
  },

  [CREATE_SPECIALIZATIONS_SUCCESS.type]: (prevState: InitialState, { payload }: PayloadAction<ISpecialities[]>) => {
    const prevSpecializations = [...prevState?.specializations];
    const newSpecializations = [...payload, ...prevSpecializations];
    return {
      ...prevState,
      specializations: newSpecializations,
    };
  },

  [CREATE_SPECIALIZATIONS_FAILURE.type]: (prevState: InitialState, { payload }: PayloadAction<{ message: string }>) => {
    return {
      ...prevState,
      error: payload.message,
    };
  },

  [DELETE_SPECIALIZATION_SUCCESS.type]: (prevState: InitialState) => {
    return {
      ...prevState,
    };
  },
};

export default createReducer(initialState, handlers);
